<template>
  <div class="template-1">
    <page-header class="page-header container">
      <h1>{{ translations.tcCampVisitationE909 }}</h1>
    </page-header>
    <security-banner v-if="!allowPage" :i18n="translations.components"></security-banner>
    <div v-if="allowPage">
      <page-body class="mx-lg">
        <camp-selector @camp_changed="page_load()" :showCamp="showCamp" :nonUSA="nonUSA"
          :tooltip="translations.tcCurrentHome" :i18n="translations.components">
        </camp-selector>
        <section class="section-1">
          <div class="g-mb-1">
            <b-button-group>
              <b-button v-if="this.okToViewAssignments" class="btn-w190"
                :variant="selectedOption === 0 ? 'primary' : 'outline'" @click="handleOptionSelect(0)">{{
                  translations.tcMyAssignments }}</b-button>
              <b-button v-if="this.okToCreateAssignments" class="btn-w190"
                :variant="selectedOption === 1 ? 'primary' : 'outline'" @click="handleOptionSelect(1)">{{
                  translations.tcManageCampAssignments }}</b-button>
              <b-button v-if="this.okToViewCampVisitationAdmin" class="btn-w190"
                :variant="selectedOption === 2 ? 'primary' : 'outline'" @click="handleOptionSelect(2)">{{
                  translations.tcCampVisitationAdmin }}</b-button>
            </b-button-group>
          </div>
        </section>
        <section class="section-1">
          <div v-if="-1 < selectedOption < 3" class="bg-block p-4">
            <iframe @load="load_finished()" ref="planner" :src="visitation_source" frameborder="0" width="100%"
              height="900"></iframe>
          </div>
        </section>
      </page-body>
      <page-footer></page-footer>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import CampSelect from '@/components/CampSelect.vue'
import constantData from '@/json/data.json'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import SecurityBanner from '@/components/SecurityBanner'
import { securityMixin } from '@/mixins/securityMixin'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'camp-visitation',
  mixins: [securityMixin, translationMixin],
  data() {
    return {
      nonUSA: true,
      selectedOption: 9,
      showCamp: false,
      translations: {},
      visitation_source: '',
      visitation_source_array: [],
      secured_controls: {
        view_909_secure_reports: '5dbb3b9f-305c-4cf9-a871-006e987c7cb0'
      },
    }
  },
  components: {
    campSelector: CampSelect,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
    securityBanner: SecurityBanner,
  },
  methods: {
    ...mapActions({
      getControlPermissions: 'user/getControlPermissions',
      setLoadingStatus: 'menu/setLoadingStatus',
    }),
    async page_load() {
      try {
        this.setLoadingStatus(true)
        await this.create_visitation_source_array()
        await this.handleOptionSelect(0)
      } catch (e) {
        console.error(e)
        this.setLoadingStatus(false)
      }
    },
    load_finished() {
      this.setLoadingStatus(false)
    },
    handleOptionSelect(opt) {
      if (this.allowPage) {
        try {
          this.setLoadingStatus(true)
          this.selectedOption = opt
          this.visitation_source = this.visitation_source_array[this.selectedOption]
        } catch (e) {
          console.error(e)
          this.setLoadingStatus(false)
        }
      }
    },
    create_visitation_source_array() {
      this.visitation_source_array = [
        `${constantData.camp_assignments_link}${this.userId}|${this.officerToolbarSelected.country_state}`,
        `${constantData.camp_visitation_link}${this.userId}|${this.officerToolbarSelected.country_state}`,
        `${constantData.camp_visitation_admin_link}${this.userId}`,
      ]
    },
  },
  computed: {
    ...mapGetters({
      isInMenu: 'menu/isInMenu',
      officerToolbarSelected: 'user/officerToolbarSelected',
      okToCreateAssignments: 'user/okToCreateAssignments',
      okToViewAssignments: 'user/okToViewAssignments',
      okToViewCampVisitationAdmin: 'user/okToViewCampVisitationAdmin',
      prefCulture: 'user/userPreferredCulture',
      userStateKey: 'user/userStateKey',
      userCampKey: 'user/userCampKey',
      userId: 'user/userId',
      userLanguageKey: 'user/userLanguageKey',
    }),
    async allowPage() {
      await this.getControlPermissions(this.secured_controls.view_909_secure_reports).then(() => {
        if (this.determineAccessibility(this.secured_controls.view_909_secure_reports)) {
          return true
        } else {
          return this.isInMenu(this.$route.path) || this.okToViewAssignments
        }
      })
    },
  },
  async created() {
    await Promise.all([this.getViewTranslations(), this.getComponentTranslations('security-banner', 'camp-select')]).then(
      (results) => {
        const componentTranslations = results[1]
        this.$set(this.translations, 'components', componentTranslations)
      }
    )
  },
}
</script>

<style scoped lang="scss">
.g-mb-1 {
  margin: 30px 0;
  text-align: center;
}
</style>
